<section
  class="notification-container at-bg-white at-rounded-2 at-shadow-large"
  [class.supplier-view]="isTradeView"
  [ngClass]="{ active: isOpen }">
  <!-- header -->
  <div
    class="at-d-flex at-align-items-center at-justify-between at-py-2 at-px-4">
    <h3 class="at-header3 at-fw-500 at-text-primary1">Notifications</h3>

    <button (click)="closeNotification()">
      <img src="/assets/images/svg/close.svg" alt="close Icon" />
    </button>
  </div>

  <!-- Tabs -->
  <div
    class="at-d-flex at-align-items-center at-justify-start at-py-2 at-px-4 at-gap-4 at-border-bottom at-border-secondary3">
    <button
      type="button"
      class="at-paragraph-small at-fw-400"
      [ngClass]="viewMode === 'all' ? 'at-text-primary2' : 'at-text-hint'"
      (click)="viewMode = 'all'">
      All ({{ allCount }})
    </button>

    <button
      type="button"
      class="at-paragraph-small at-fw-400"
      [ngClass]="viewMode === 'unread' ? 'at-text-primary2' : 'at-text-hint'"
      (click)="viewMode = 'unread'">
      Unread ({{ unreadCount }})
    </button>
  </div>

  <!-- body -->
  @if (viewMode === 'all') {
    @for (item of allNotificationList; track item) {
      <ecommerce-notification-item
        [notificationItem]="item"
        (toggleUnread)="toggleUnreadNotification($event)" />
    } @empty {
      <div
        class="at-col at-d-flex at-align-items-center at-justify-center at-fw-400 at-text-hint at-paragraph-regular">
        No recent notifications
      </div>
    }
  } @else if (viewMode === 'unread') {
    @for (item of unreadNotificationList; track item) {
      <ecommerce-notification-item
        [notificationItem]="item"
        (toggleUnread)="toggleUnreadNotification($event)" />
    } @empty {
      <div
        class="at-col at-d-flex at-align-items-center at-justify-center at-fw-400 at-text-hint at-paragraph-regular">
        No recent notifications
      </div>
    }
  }
</section>
