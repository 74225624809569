<div class="at-border-top at-border-secondary4">
  @if (supplierProductList && supplierProductList.length) {
    <div class="cart-container">
      <div class="items-container">
        <div class="">
          <ecommerce-cart-item
            [supplierProductList]="supplierProductList"
            [isUploadItemLoading]="isUploadItemLoading"
            [isRemoveItemLoading]="isRemoveItemLoading"
            (update)="updateItem($event)"
            (delete)="deleteItem($event)" />
        </div>
      </div>

      <div class="at-p-8">
        <div class="at-d-flex at-justify-between at-mb-4">
          <div class="at-fw-400">
            {{ 'cart_management_details_screen_subtotal_label' | transloco }}:
          </div>
          <div class="at-fw-400">{{ financials.subtotal.formatted }}</div>
        </div>

        <div class="at-d-flex at-justify-between at-mb-4">
          <div class="at-fw-400">
            {{ 'cart_management_details_screen_shipping_label' | transloco }}:
          </div>
          <div class="at-fw-400">{{ financials.shipping.formatted }}</div>
        </div>

        <div class="at-d-flex at-justify-between at-mb-4">
          <div class="at-header3 at-fw-500">
            {{ 'cart_management_details_screen_total_label' | transloco }}:
          </div>
          <div class="at-header3 at-fw-500">
            {{ financials.total.formatted }}
          </div>
        </div>

        <div class="at-d-flex at-gap-6 at-justify-between at-mt-8">
          <ecommerce-button
            (click)="closeCart()"
            id="cart_screen_continue_shopping"
            [label]="
              'cart_management_details_screen_continue_shopping_button'
                | transloco
            "
            class="at-col"
            btnClass="at-border at-border-primary2 at-text-primary2 at-py-4 at-w-100 at-rounded-pill" />

          <ecommerce-button
            [disabled]="!can_checkout && !isDeploy"
            (click)="viewBag()"
            id="cart_screen_view_bag"
            [label]="
              'cart_management_details_screen_view_bag_button' | transloco
            "
            class="at-col"
            btnClass="at-bg-primary2 at-text-white at-py-4 at-w-100 at-rounded-pill" />
        </div>
      </div>
    </div>
  } @else {
    <!-- cart empty state -->
    <div
      class="at-h-100 at-d-flex at-flex-column at-justify-center at-align-items-center at-text-center empty-cart">
      <img src="assets/images/svg/empty_cart.svg" alt="empty cart" />

      <h4 class="at-mt-8 at-header4">
        {{ 'cart_management_details_screen_empty_cart_header' | transloco }}
      </h4>

      <p class="at-mt-2 at-text-hint at-paragraph-small">
        {{ 'cart_management_details_screen_empty_cart_paragraph' | transloco }}
      </p>
    </div>
  }
</div>
