<section class="at-d-xl-flex at-d-none at-align-items-center">
  @if (loggedIn) {
    <!-- notification -->
    <div
      class="at-position-relative"
      [class.at-me-4]="
        selectedDropdownItem() &&
        selectedDropdownItem().id !== 2 &&
        !hideCartIcon()
      "
      ecommerceClickOutSide
      (clickOutside)="notificationToggle(false)">
      <button
        type="button"
        class="at-d-flex at-align-items-center"
        (click)="notificationToggle(true)">
        <div class="at-me-1">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.66666 14.5C6.75133 14.7885 6.92717 15.0417 7.16783 15.2219C7.40849 15.4021 7.70103 15.4994 8.00166 15.4994C8.30229 15.4994 8.59483 15.4021 8.83549 15.2219C9.07615 15.0417 9.25198 14.7885 9.33666 14.5"
              stroke="#101B19"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M8 2V0.5"
              stroke="#101B19"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7C13 11.6973 14 12.5 14 12.5H2C2 12.5 3 11.2227 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2Z"
              stroke="#101B19"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>

        <div class="at-paragraph-extra-small at-fw-400">
          {{ unreadNotificationCount }}
        </div>
      </button>

      <ecommerce-notification />
    </div>

    <div class="at-d-flex at-align-items-center at-me-6">
      @if (
        selectedDropdownItem() &&
        selectedDropdownItem().id !== 2 &&
        !hideCartIcon()
      ) {
        <!-- cart -->
        <button
          class="at-d-flex at-align-items-center"
          (click)="cartToggle(true)">
          <div class="at-me-1">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1410_23870)">
                <path
                  d="M4 12.7499H10.614C10.8502 12.75 11.0788 12.6664 11.2593 12.5139C11.4398 12.3615 11.5605 12.1502 11.6 11.9173L13.358 1.58395C13.3977 1.35119 13.5184 1.13996 13.6989 0.987686C13.8793 0.835406 14.1079 0.7519 14.344 0.751953H15"
                  stroke="#101B19"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.25 14.75C5.11193 14.75 5 14.6381 5 14.5C5 14.3619 5.11193 14.25 5.25 14.25"
                  stroke="#101B19" />
                <path
                  d="M5.25 14.75C5.38807 14.75 5.5 14.6381 5.5 14.5C5.5 14.3619 5.38807 14.25 5.25 14.25"
                  stroke="#101B19" />
                <path
                  d="M10.25 14.75C10.1119 14.75 10 14.6381 10 14.5C10 14.3619 10.1119 14.25 10.25 14.25"
                  stroke="#101B19" />
                <path
                  d="M10.25 14.75C10.3881 14.75 10.5 14.6381 10.5 14.5C10.5 14.3619 10.3881 14.25 10.25 14.25"
                  stroke="#101B19" />
                <path
                  d="M11.9686 9.75007H3.92131C3.47536 9.75 3.04222 9.60093 2.69072 9.32647C2.33922 9.05207 2.08952 8.668 1.98131 8.2354L1.01464 4.36873C0.996193 4.29499 0.994806 4.21801 1.01057 4.14365C1.02635 4.06929 1.05887 3.99951 1.10566 3.93961C1.15245 3.8797 1.21228 3.83125 1.28061 3.79795C1.34894 3.76464 1.42396 3.74735 1.49997 3.74739H12.9893"
                  stroke="#101B19"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1410_23870">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="at-paragraph-extra-small at-fw-400">
            {{ itemsCount }}
          </div>
        </button>
      }
    </div>

    <div class="splitter at-bg-secondary4"></div>

    <button class="at-ms-6" (click)="toggleProfileImage()">
      <ecommerce-profile-icon
        [width]="'2.5rem'"
        [height]="'2.5rem'"
        [placeholder]="profileImagePlaceholder | uppercase"
        [imageUrl]="profileImageUrl" />
    </button>
  } @else {
    <!-- Cart -->
    <button
      class="at-d-flex at-align-items-center at-me-6 at-p-2"
      (click)="cartToggle(true)">
      <div class="at-me-1">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_547_858)">
            <path
              d="M4 12.7499H10.614C10.8502 12.75 11.0788 12.6664 11.2593 12.5139C11.4398 12.3615 11.5605 12.1502 11.6 11.9173L13.358 1.58395C13.3977 1.35119 13.5184 1.13996 13.6989 0.987686C13.8793 0.835406 14.1079 0.7519 14.344 0.751953H15"
              stroke="#101B19"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M5.25 14.75C5.11193 14.75 5 14.6381 5 14.5C5 14.3619 5.11193 14.25 5.25 14.25"
              stroke="#101B19" />
            <path
              d="M5.25 14.75C5.38807 14.75 5.5 14.6381 5.5 14.5C5.5 14.3619 5.38807 14.25 5.25 14.25"
              stroke="#101B19" />
            <path
              d="M10.25 14.75C10.1119 14.75 10 14.6381 10 14.5C10 14.3619 10.1119 14.25 10.25 14.25"
              stroke="#101B19" />
            <path
              d="M10.25 14.75C10.3881 14.75 10.5 14.6381 10.5 14.5C10.5 14.3619 10.3881 14.25 10.25 14.25"
              stroke="#101B19" />
            <path
              d="M11.9689 9.74974H3.92161C3.47566 9.74968 3.04252 9.60061 2.69102 9.32614C2.33952 9.05174 2.08982 8.66768 1.98161 8.23508L1.01494 4.3684C0.996498 4.29466 0.995112 4.21769 1.01088 4.14333C1.02665 4.06897 1.05917 3.99919 1.10596 3.93928C1.15275 3.87938 1.21258 3.83093 1.28092 3.79762C1.34924 3.76432 1.42426 3.74703 1.50028 3.74707H12.9896"
              stroke="#101B19"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_547_858">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="at-paragraph-extra-small at-fw-400">
        {{ itemsCount }}
      </div>
    </button>

    <div class="splitter at-bg-secondary4 at-me-6"></div>

    <a routerLink="/login">
      <ecommerce-button
        [id]="'sign_in_top_navbar_button'"
        [label]="'landing_screen_sign_in_button' | transloco"
        btnClass="at-rounded-pill at-border-primary2 at-text-primary2 at-py-3 at-px-4 at-me-4" />
    </a>

    <a routerLink="/register">
      <ecommerce-button
        [id]="'join_us_top_navbar_button'"
        [label]="'landing_screen_join_us_button' | transloco"
        btnClass="at-rounded-pill at-border-primary2 at-bg-primary2 at-text-white at-py-3 at-px-4" />
    </a>
  }
</section>

<!-- End Responsive (Tablet and Mobile) -->
<section class="at-d-flex at-d-xl-none at-gap-4 at-gap-md-6">
  <!-- Cart -->
  <button
    class="at-d-flex at-align-items-center at-p-2"
    (click)="cartToggle(true)">
    <div class="at-me-1">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 19.1249H15.921C16.2753 19.125 16.6182 18.9996 16.889 18.7709C17.1597 18.5423 17.3407 18.2253 17.4 17.8759L20.037 2.37593C20.0965 2.02678 20.2776 1.70994 20.5483 1.48153C20.819 1.25311 21.1618 1.12785 21.516 1.12793H22.5"
          stroke="#101B19"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M7.875 22.125C7.66789 22.125 7.5 21.9571 7.5 21.75C7.5 21.5429 7.66789 21.375 7.875 21.375"
          stroke="#101B19"
          stroke-width="1.5" />
        <path
          d="M7.875 22.125C8.08211 22.125 8.25 21.9571 8.25 21.75C8.25 21.5429 8.08211 21.375 7.875 21.375"
          stroke="#101B19"
          stroke-width="1.5" />
        <path
          d="M15.375 22.125C15.1679 22.125 15 21.9571 15 21.75C15 21.5429 15.1679 21.375 15.375 21.375"
          stroke="#101B19"
          stroke-width="1.5" />
        <path
          d="M15.375 22.125C15.5821 22.125 15.75 21.9571 15.75 21.75C15.75 21.5429 15.5821 21.375 15.375 21.375"
          stroke="#101B19"
          stroke-width="1.5" />
        <path
          d="M17.9529 14.6251H5.88193C5.21301 14.625 4.5633 14.4014 4.03605 13.9897C3.5088 13.5781 3.13425 13.002 2.97193 12.3531L1.52193 6.55309C1.49426 6.44248 1.49218 6.32702 1.51583 6.21548C1.53949 6.10394 1.58827 5.99927 1.65846 5.90941C1.72864 5.81955 1.81839 5.74688 1.92089 5.69692C2.02338 5.64696 2.13591 5.62103 2.24993 5.62109H19.4839"
          stroke="#101B19"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>

    <div class="at-paragraph-extra-small at-fw-400 at-text-primary1">
      {{ itemsCount }}
    </div>
  </button>

  <!-- Menu -->
  <button
    id="hamburger_responsive_top_navbar_button"
    type="button"
    (click)="isResponsiveMenuClosed = false">
    <svg
      width="31"
      height="24"
      viewBox="0 0 31 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.875 6.00122H27.125M3.875 12.0012H27.125M3.875 18.0012H27.125"
        stroke="#1E285F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </button>
</section>

@if (isProfileDropdownVisible) {
  <ecommerce-profile-dropdown-menu
    [profileImagePlaceholder]="profileImagePlaceholder"
    [fullName]="fullName"
    [profileImageUrl]="profileImageUrl"
    [selectedDropdownItem]="selectedDropdownItem()"
    (triggerProfileImage)="toggleProfileImage()"
    (openLogoutModal)="openModal()" />
}

<ecommerce-off-canvas />

<ng-template #cartTemplate>
  <ecommerce-cart />
</ng-template>

<ng-template #logoutModalTemplate>
  <div class="at-d-flex at-justify-end at-mt-8">
    <div class="at-col-6 at-pe-2">
      <ecommerce-button
        [label]="'landing_screen_logout_modal_cancel_button' | transloco"
        [id]="'landing_screen_logout_modal_cancel_button'"
        btnClass="
            at-rounded-pill
            at-border
            at-border-primary2
            at-text-primary2
            at-w-100
            at-py-3
            at-px-4
          "
        (click)="closeModal()" />
    </div>

    <div class="at-col-6 at-ps-2">
      @if (!logoutLoading) {
        <ecommerce-button
          [label]="'landing_screen_logout_modal_logout_button' | transloco"
          [id]="'landing_screen_logout_modal_logout_button'"
          (click)="logout()"
          btnClass="
            at-bg-primary2
            at-rounded-pill
            at-text-white
            at-py-3
            at-px-4
            at-w-100
          " />
      } @else {
        <button
          type="button"
          disabled="true"
          class="btn at-bg-primary2 at-rounded-pill at-text-white at-py-3 at-d-block at-w-100 at-text-center">
          <ecommerce-spinner [isSmall]="true" [isDark]="true" />
        </button>
      }
    </div>
  </div>
</ng-template>

<div
  class="overlay"
  [ngClass]="{ active: isProfileDropdownVisible }"
  (click)="toggleProfileImage()"></div>

<!-- Responsive Menu -->
<section
  class="at-bg-white responsive-menu"
  [class.open]="!isResponsiveMenuClosed">
  <div class="at-text-end at-p-6">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="at-cursor-pointer"
      (click)="isResponsiveMenuClosed = true">
      <g clip-path="url(#clip0_174_1107)">
        <path
          d="M0.75 23.249L23.25 0.749023"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M23.25 23.249L0.75 0.749023"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_174_1107">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>

  <div class="at-p-6">
    <button
      type="button"
      id="responsive_menu_home"
      class="at-fw-500 at-mb-6 at-header3 at-d-block at-text-primary1"
      (click)="handleMenuNavigation('/', '#hero-section')">
      Home
    </button>

    <button
      type="button"
      id="responsive_menu_home"
      class="at-fw-500 at-mb-6 at-header3 at-d-block at-text-primary1"
      (click)="handleMenuNavigation('/', '#buyers-section')">
      Buyers
    </button>

    <button
      type="button"
      id="responsive_menu_home"
      class="at-fw-500 at-mb-6 at-header3 at-d-block at-text-primary1"
      (click)="handleMenuNavigation('/', '#suppliers-section')">
      Suppliers
    </button>

    <button
      type="button"
      id="responsive_menu_home"
      class="at-fw-500 at-mb-6 at-header3 at-d-block at-text-primary1"
      (click)="handleMenuNavigation('/', '#testimonials-section')">
      Testimonials
    </button>

    <button
      type="button"
      id="responsive_menu_home"
      class="at-fw-500 at-mb-6 at-header3 at-d-block at-text-primary1"
      (click)="handleMenuNavigation('/', '#faq-section')">
      FAQ
    </button>

    <button
      type="button"
      id="responsive_menu_home"
      class="at-fw-500 at-mb-6 at-header3 at-d-block at-text-primary1"
      (click)="handleMenuNavigation('/product-store')">
      Product store
    </button>

    <div class="at-pt-6 at-border-top at-border-secondary3">
      <a class="at-d-block at-mb-6" routerLink="/login">
        <ecommerce-button
          [id]="'sign_in_top_navbar_button'"
          [label]="'landing_screen_sign_in_button' | transloco"
          btnClass="at-rounded-pill at-border-primary2 at-text-primary2 at-py-4 at-fw-500 at-w-100 at-text-center at-paragraph-regular" />
      </a>

      <a class="at-d-block" routerLink="/register">
        <ecommerce-button
          [id]="'join_us_top_navbar_button'"
          [label]="'landing_screen_join_us_button' | transloco"
          btnClass="at-rounded-pill at-border-primary2 at-bg-primary2 at-text-white at-py-4 at-fw-500 at-w-100 at-text-center at-paragraph-regular" />
      </a>
    </div>
  </div>
</section>
