import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@ui-components';
import { CartItemComponent } from './card-item/cart-item.component';
import { Router, RouterModule } from '@angular/router';
import { CartSyncService } from '../../../common/components/top-navbar/cart-sync.service';
import { TranslocoModule } from '@jsverse/transloco';
import {
  CartResponse,
  Financials,
  SupplierItem,
  UpdatedItem,
} from './cart.interface';
import { CartService } from '../../../common/services/cart.service';
import { DeployService } from '../../../common/services/deploy.service';
import { ToastService } from '../../../common/components/toast/toast.service';
import * as amplitude from '@amplitude/analytics-browser';

@Component({
  selector: 'ecommerce-cart',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CartItemComponent,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})

/**
 * Represents the CartComponent class.
 * This component is responsible for managing the cart functionality.
 */
export class CartComponent {
  supplierProductList: SupplierItem[] = [];
  cartReference!: string;
  financials!: Financials;
  itemsCount!: number;
  isUploadItemLoading!: boolean;
  isRemoveItemLoading!: boolean;
  unableToViewBag!: boolean;
  isDeploy!: boolean;
  can_checkout!: boolean;

  constructor(
    private cartSyncService: CartSyncService,
    private cartService: CartService,
    private router: Router,
    private toastService: ToastService,
    private deployService: DeployService
  ) {
    this.cartReference = JSON.parse(
      JSON.stringify(localStorage.getItem('cart_reference'))
    );
    this.updateCartWithLatestData();
    this.isDeploy = this.deployFeature('dev');
  }

  deployFeature(value: 'dev' | 'test' | 'uat'): boolean {
    return !this.deployService.transform(value);
  }

  /**
   * Closes the cart by updating the off-canvas state.
   */
  closeCart() {
    this.cartSyncService.isCartOpen.set({ isOpen: false });
  }

  /**
   * Updates an item in the cart.
   * updatedItem - The updated item object.
   */
  updateItem(updatedItem: UpdatedItem) {
    this.isUploadItemLoading = true;
    this.cartService
      .updateCartItems(this.cartReference, updatedItem.productId, {
        quantity: updatedItem.quantity,
      })
      .subscribe((response: CartResponse) => {
        this.isUploadItemLoading = false;
        this.cartSyncService.updateCart.set(response);
        this.supplierProductList = response.cart.supplier_items;
        this.financials = response.cart.financials as Financials;
        this.itemsCount = response.cart.items_count;
        this.toastService.show({
          toastState: 'success',
          message: response.message as string,
        });
      });
  }

  /**
   * Deletes an item from the cart.
   *  itemId - The ID of the item to be deleted.
   */
  deleteItem(itemId: string) {
    this.isRemoveItemLoading = true;
    this.cartService
      .deleteCartItem(this.cartReference, itemId)
      .subscribe((response: CartResponse) => {
        this.isRemoveItemLoading = false;
        this.cartSyncService.updateCart.set(response);
        this.supplierProductList = response.cart.supplier_items;
        this.financials = response.cart.financials as Financials;
        this.itemsCount = response.cart.items_count;
        this.toastService.show({
          toastState: 'success',
          message: response.message as string,
        });
      });
  }

  updateCartWithLatestData() {
    effect(() => {
      const response: CartResponse = this.cartSyncService.updateCart();
      this.itemsCount = response.cart.items_count;
      localStorage.setItem('itemsCount', this.itemsCount.toString());
      this.financials = response.cart.financials;
      this.supplierProductList = response.cart.supplier_items;
      this.can_checkout = response.can_checkout;

      this.cartReference = response.cart.reference;
      localStorage.setItem('cart_reference', this.cartReference);
    });
  }

  /**
   * Closes the cart and navigates to the appropriate page based on the user's authentication status.
   */
  viewBag() {
    this.closeCart();

    const email = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') as string).email
      : 'anonymous_user';
    amplitude.track(
      'track_order_placement_screen_items_drawer_review_order_button_clicked',
      {
        user_email: email,
        timestamp: Math.floor(Date.now() / 1000),
      }
    );

    if (localStorage.getItem('token')) {
      this.router.navigate(['/order-steps/shopping-bag']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
