import { Injectable, signal } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { BOXO_APIS } from '../../constants/endpoints.constant';
import { Notification, NotificationGroup } from './notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  isNotificationOpen = signal<boolean>(false);
  allNotificationList = signal<NotificationGroup[]>([]);
  unreadNotificationList = signal<NotificationGroup[]>([]);
  unreadNotificationCount = signal<number>(0);
  todayDate = new Date();

  addNewNotificationItem(newNotification: Notification) {
    const formattedToday = this.getFormattedTodayDate();

    if (this.allNotificationList()[0].date === formattedToday) {
      this.allNotificationList()[0].notifications.unshift(newNotification);
      this.unreadNotificationList()[0].notifications.unshift(newNotification);
      this.unreadNotificationCount.set(this.unreadNotificationCount() + 1);
    } else {
      const notificationGroup: NotificationGroup = {
        date: formattedToday,
        notifications: [newNotification],
      };

      this.allNotificationList().unshift(notificationGroup);
      this.unreadNotificationList().unshift(notificationGroup);
      this.unreadNotificationCount.set(this.unreadNotificationCount() + 1);
    }
  }

  getFormattedTodayDate() {
    const yyyy = this.todayDate.getFullYear();
    const month = this.todayDate.getMonth() + 1; // Months start at 0!
    const day = this.todayDate.getDate();
    let dd, mm;

    if (day < 10) dd = '0' + dd;
    if (month < 10) mm = '0' + mm;
    return dd + '-' + mm + '-' + yyyy;
  }

  getNotifications(getUnreadNotificationsOnly: boolean) {
    const url =
      BOXO_APIS.GET_NOTIFICATIONS +
      (getUnreadNotificationsOnly ? '?unread=1' : '');
    return this.get(url);
  }

  toggleUnreadNotifications(id: string) {
    const url = BOXO_APIS.PATCH_NOTIFICATIONS.replace('{id}', id);
    return this.patch(url, {});
  }
}
