import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductTitleCombinationComponent } from '../../../../common/components/product-title-combination/product-title-combination.component';
import { QuantityControllerComponent } from '../../../../common/components/quantity-controller/quantity-controller.component';
import { TruncatePipe } from '../../../../common/pipes/truncate.pipe';
import { ButtonComponent } from '@ui-components';
import { TranslocoModule } from '@jsverse/transloco';
import { SupplierItem, UpdatedItem } from '../cart.interface';
import { SpinnerComponent } from '../../../../common/components/spinner/spinner.component';
import { DeployService } from '../../../../common/services/deploy.service';

@Component({
  selector: 'ecommerce-cart-item',
  standalone: true,
  imports: [
    CommonModule,
    ProductTitleCombinationComponent,
    TruncatePipe,
    QuantityControllerComponent,
    ButtonComponent,
    TranslocoModule,
    SpinnerComponent,
  ],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.scss',
})
export class CartItemComponent {
  supplierProductList = input.required<SupplierItem[]>();
  isUploadItemLoading = input.required<boolean>();
  isRemoveItemLoading = input.required<boolean>();
  update = output<UpdatedItem>();
  delete = output<string>();
  quantity = 1;
  selectedItemId!: number;
  isDeploy!: boolean;

  constructor(private deployService: DeployService) {
    this.isDeploy = this.deployFeature('dev');
  }

  deployFeature(value: 'dev' | 'test' | 'uat'): boolean {
    return !this.deployService.transform(value);
  }

  decreaseQuantity(itemId: number, quantity: number) {
    this.selectedItemId = itemId;
    this.quantity = quantity;
    this.quantity--;
    this.updateItem(itemId.toString());
  }

  increaseQuantity(itemId: number, quantity: number) {
    this.selectedItemId = itemId;
    this.quantity = quantity;
    this.quantity++;
    this.updateItem(itemId.toString());
  }

  updateItem(itemId: string) {
    const updatedItem: UpdatedItem = {
      productId: itemId,
      quantity: this.quantity,
    };
    this.update.emit(updatedItem);
  }

  deleteItem(itemId: number) {
    this.selectedItemId = itemId;
    this.delete.emit(itemId.toString());
  }
}
