import { Injectable, signal } from '@angular/core';
import { OffCanvas } from '../off-canvas/off-canvas.interface';
import { BaseService } from '../../services/base.service';
import {
  CartResponse,
  Financials,
} from '../../../pages/marketplace/cart/cart.interface';

@Injectable({
  providedIn: 'root',
})
export class CartSyncService extends BaseService {
  isCartOpen = signal<OffCanvas>({ isOpen: false });
  updateCart = signal<CartResponse>({
    cart: {
      items_count: 0,
      reference: '',
      supplier_items: [],
      financials: {} as Financials,
    },
    can_checkout: false,
  });
}
