<div
  class="at-rounded-pill quantity-container at-border at-border-primary1 at-d-flex at-align-items-center">
  <button
    type="button"
    [disabled]="quantity() === product_minimum_quantity()"
    class="at-col at-text-center"
    (click)="decreaseProductQuantity.emit()">
    -
  </button>

  <span class="at-col at-text-center">{{ quantity() }}</span>

  <button
    type="button"
    [disabled]="quantity() === product_maximum_quantity()"
    class="at-col at-text-center"
    (click)="increaseProductQuantity.emit()">
    +
  </button>
</div>
