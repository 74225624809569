import { Injectable } from '@angular/core';
import { BOXO_APIS } from '../constants/endpoints.constant';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CartService extends BaseService {
  getCartItems(cartReference: string) {
    const url =
      BOXO_APIS.GET_CART_ITEMS +
      (cartReference ? '?reference=' + cartReference : '');
    return this.get(url);
  }

  addToCart(reference: string, productId: number, quantity: number) {
    const url = BOXO_APIS.ADD_TO_CART.replace('{reference}', reference);
    const body = {
      product_id: productId,
      quantity: quantity,
    };
    return this.post(url, body);
  }

  updateCartItems(reference: string, id: string, body: unknown) {
    const url = BOXO_APIS.UPDATE_CART_ITEMS.replace(
      '{reference}',
      reference
    ).replace('{id}', id);
    return this.put(url, body);
  }

  deleteCartItem(reference: string, id: string) {
    const url = BOXO_APIS.DELETE_CART_ITEMS.replace(
      '{reference}',
      reference
    ).replace('{id}', id);
    return this.delete(url);
  }

  checkoutCart(reference: string, shipping_address_id: number) {
    const url = BOXO_APIS.CHECKOUT_CART.replace('{reference}', reference);
    const body = {
      shipping_address_id: shipping_address_id,
    };
    return this.post(url, body);
  }
}
