import { Component, TemplateRef, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@ui-components';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { CartSyncService } from '../top-navbar/cart-sync.service';
import { CartResponse } from '../../../pages/marketplace/cart/cart.interface';
import { OffCanvas } from './off-canvas.interface';

@Component({
  selector: 'ecommerce-off-canvas',
  standalone: true,
  imports: [CommonModule, ButtonComponent, TruncatePipe, TranslocoModule],
  templateUrl: './off-canvas.component.html',
  styleUrl: './off-canvas.component.scss',
})
export class OffCanvasComponent {
  customizeContent!: TemplateRef<unknown>;
  isOpen!: boolean;
  title!: string;
  closeIcon!: boolean;
  itemsCount!: number;
  is_out_of_stock!: boolean;

  constructor(
    private cartSyncService: CartSyncService,
    private translocoService: TranslocoService
  ) {
    this.cartToggle();
    this.updateCart();
  }

  closeSidebar() {
    this.cartSyncService.isCartOpen.set({ isOpen: false });
  }

  cartToggle() {
    effect(() => {
      const offCanvas = <OffCanvas>this.cartSyncService.isCartOpen();
      if (offCanvas) {
        if (offCanvas.isOpen) {
          document.body.classList.add('body-no-scroll');
        } else {
          document.body.classList.remove('body-no-scroll');
        }
        this.isOpen = !!offCanvas.isOpen;
        this.is_out_of_stock = !!offCanvas.is_out_of_stock;
        this.customizeContent =
          offCanvas.customizeContent as TemplateRef<unknown>;
        this.title = offCanvas.title as string;
        this.closeIcon = !!offCanvas.closeIcon;
      }
    });
  }

  updateCart() {
    effect(() => {
      const response = <CartResponse>this.cartSyncService.updateCart();
      this.itemsCount = response.cart.items_count;
      localStorage.setItem('itemsCount', this.itemsCount.toString());
      this.title =
        this.translocoService.translate(
          'cart_management_details_screen_items_title'
        ) +
        ' (' +
        this.itemsCount +
        ')';
    });
  }
}
