import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonComponent } from '@ui-components';
import { ProfileIconComponent } from '../../profile-icon/profile-icon.component';
import { DropDown } from '../../dropdown/dropdown.interface';

@Component({
  selector: 'ecommerce-profile-dropdown-menu',
  standalone: true,
  imports: [
    CommonModule,
    ProfileIconComponent,
    ButtonComponent,
    TranslocoModule,
    RouterModule,
  ],
  templateUrl: './profile-dropdown-menu.component.html',
  styleUrl: './profile-dropdown-menu.component.scss',
})
export class ProfileDropdownMenuComponent {
  selectedDropdownItem = input<DropDown>();
  profileImagePlaceholder = input<string>('');
  fullName = input<string>('');
  profileImageUrl = input<string>('');
  openLogoutModal = output();
  triggerProfileImage = output();

  openModal() {
    this.openLogoutModal.emit();
  }

  toggleProfileImage() {
    this.triggerProfileImage.emit();
  }
}
