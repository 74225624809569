import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ecommerce-quantity-controller',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quantity-controller.component.html',
  styleUrl: './quantity-controller.component.scss',
})
export class QuantityControllerComponent {
  product_minimum_quantity = input.required<number>();
  quantity = input.required<number>();
  product_maximum_quantity = input<number>();
  decreaseProductQuantity = output<void>();
  increaseProductQuantity = output<void>();
}
