import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { SettingsService } from '../../../pages/auth/settings/settings.service';
import { User } from '../../interfaces/order.interface';
import { ProfileIconService } from './profile-icon.service';

@Component({
  selector: 'ecommerce-profile-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-icon.component.html',
  styleUrl: './profile-icon.component.scss',
})
export class ProfileIconComponent {
  placeholder = input<string>();
  width = input<string>();
  height = input<string>();
  imageUrl = input<string>();
  fontSize = input<string>();
  profileData!: User;

  constructor(
    private settingsService: SettingsService,
    private profileIconService: ProfileIconService
  ) {}

  /**
   * Handles the error event for the profile image.
   * If the profile image fails to load, it checks if the error event was triggered before
   * or if the last successful image render was more than 24 hours ago.
   * If any conditions are met, it calls the getProfile method to fetch a new image.
   * Otherwise, it logs an error message indicating the image may be corrupted on the server.
   *
   * @param {Event} event - The error event triggered by the image element.
   */
  onProfileImageError(event: Event) {
    // Get today's date and timestamp
    const todayDate = {
      date: new Date(),
      timestamp: new Date().getTime(),
    };

    // Retrieve the last profile image render date from local storage
    const profileImageRendered = localStorage.getItem(
      'profileImageRenderedDate'
    );
    const profileImageRenderedDate = JSON.parse(<string>profileImageRendered);

    // Check if the event is an error event and the target element exists
    // Also, ensure that either the image has never been rendered before,
    // or the last render was more than 24 hours ago
    if (
      event.type === 'error' &&
      event.target &&
      (!profileImageRendered ||
        todayDate.timestamp > +profileImageRenderedDate.timestamp + 86400000) //24 hours
    ) {
      this.getProfile(event.target as HTMLImageElement);
    } else {
      console.error('Profile image maybe corrupted on the server');
    }
  }

  /**
   *
   *
   * @param {HTMLImageElement} imageTarget
   * @memberof ProfileIconComponent
   */
  getProfile(imageTarget: HTMLImageElement) {
    this.settingsService.getProfile().subscribe(response => {
      if (response.user) {
        this.profileData = response.user;
        localStorage.setItem('user', JSON.stringify(response.user));
        if (this.profileData.profile_image) {
          imageTarget.src = this.profileData.profile_image.url;
          this.profileIconService.profileImageUrl.set(
            this.profileData.profile_image.url
          );
        }

        const profileImageRenderedDate = {
          date: new Date(),
          timestamp: new Date().getTime(),
        };
        localStorage.setItem(
          'profileImageRenderedDate',
          JSON.stringify(profileImageRenderedDate)
        );
      }
    });
  }
}
