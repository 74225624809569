@if (notificationItem()) {
  <div class="at-py-2 at-px-4 at-text-hint at-text-start">
    {{ notificationDate() }}
  </div>

  @if (
    notificationItem().notifications && notificationItem().notifications.length
  ) {
    @for (
      notificationItem of notificationItem().notifications;
      track notificationItem.id
    ) {
      <div class="at-p-4 at-border-bottom at-border-secondary2">
        <div class="at-d-flex">
          <div class="at-col-auto at-pe-4">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2026_4653)">
                <path
                  d="M8 17.6307L11.2667 22.2667C11.3906 22.4518 11.5569 22.6046 11.7518 22.7124C11.9467 22.8202 12.1645 22.88 12.3872 22.8866C12.6098 22.8933 12.8308 22.8467 13.0318 22.7507C13.2328 22.6547 13.4079 22.512 13.5427 22.3347L24 9.104"
                  stroke="#2E7D32"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M1 15.9987C1 19.9769 2.58035 23.7922 5.3934 26.6053C8.20644 29.4183 12.0218 30.9987 16 30.9987C19.9782 30.9987 23.7936 29.4183 26.6066 26.6053C29.4196 23.7922 31 19.9769 31 15.9987C31 12.0204 29.4196 8.2051 26.6066 5.39206C23.7936 2.57901 19.9782 0.998657 16 0.998657C12.0218 0.998657 8.20644 2.57901 5.3934 5.39206C2.58035 8.2051 1 12.0204 1 15.9987Z"
                  stroke="#2E7D32"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2026_4653">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div class="at-col at-text-start">
            <h4 class="at-header4 at-fw-500 at-mb-2">
              {{ notificationItem.title }}
            </h4>

            <p class="at-text-hint at-fw-400 at-mb-2">
              {{ notificationItem.description }}
            </p>

            @if (notificationDate() === 'Today') {
              <div class="at-text-hint at-fw-400 at-mb-2 at-paragraph-small">
                {{ notificationItem.created_at.timestamp * 1000 | dateAgo }}
              </div>
            }

            <ecommerce-button
              id="place_your_first_order"
              label="Place your first order"
              (click)="setNotificationNavigation(notificationItem)"
              btnClass="at-rounded-pill at-border at-border-primary2 at-py-3 at-px-4 at-text-primary2" />
          </div>

          <div class="at-col-auto at-mt-1 at-ps-4">
            @if (!notificationItem.is_read) {
              <span
                class="at-rounded-circle notification-status at-bg-primary2 at-d-inline-block"></span>
            }
          </div>
        </div>
      </div>
    }
  }
}
