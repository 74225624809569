import { Injectable, signal } from '@angular/core';
import { BaseService } from '../../../common/services/base.service';
import { BOXO_APIS } from '../../../common/constants/endpoints.constant';
import { Avatar } from './avatar.interface';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  avatarContent = signal<Avatar>({
    name: '',
    image: {
      url: '',
      alt: '',
      name: '',
    },
  });

  isDimmed = signal<boolean>(false);
  isTouched = signal<boolean>(false);
  isSettingsLoading = signal<boolean>(false);

  getProfile() {
    const url = BOXO_APIS.GET_PROFILE;
    return this.get(url);
  }

  updateProfile(data: unknown) {
    const url = BOXO_APIS.UPDATE_PROFILE;
    return this.post(url, data);
  }

  getSupplierProfile() {
    const url = BOXO_APIS.GET_SUPPLIER_PROFILE;
    return this.get(url);
  }

  updateSupplierProfile(data: unknown) {
    const url = BOXO_APIS.UPDATE_SUPPLIER_PROFILE;
    return this.post(url, data);
  }
}
