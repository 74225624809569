import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeployService {
  transform(value: 'local' | 'dev' | 'test' | 'uat'): boolean {
    switch (value) {
      case 'local': {
        return (
          !!environment.baseUrl.includes('dev') ||
          !!window.location.origin.includes('localhost')
        );
      }
      case 'dev': {
        return (
          !!environment.baseUrl.includes('dev') &&
          !!window.location.origin.includes('dev')
        );
      }
      case 'test': {
        return !!(
          environment.baseUrl.includes('dev') ||
          environment.baseUrl.includes('test')
        );
      }
      case 'uat': {
        return !!(
          environment.baseUrl.includes('dev') ||
          environment.baseUrl.includes('test') ||
          environment.baseUrl.includes('uat')
        );
      }
    }
  }
}
