@if (supplierProductList() && supplierProductList().length) {
  @for (
    supplierProduct of supplierProductList();
    track supplierProduct;
    let i = $index
  ) {
    <section class="at-border-bottom at-border-secondary4 at-px-7 at-pt-7">
      <h4 class="at-header4 at-pt-6 at-pb-2 at-fw-500">
        {{ supplierProduct.supplier.company_name }}
      </h4>

      @for (item of supplierProduct.items; track item; let j = $index) {
        <div
          class="at-py-6 at-d-flex at-justify-between at-align-items-start at-gap-6">
          <section>
            <img
              [src]="item.product.main_image.url"
              [alt]="item.product.main_image.name"
              [width]="126"
              [height]="126"
              class="at-rounded-2"
              loading="lazy" />
          </section>

          <!-- content -->
          <section class="at-d-flex at-flex-column at-justify-between">
            <ecommerce-product-title-combination
              [product]="item.product"
              [customClasses]="'at-fw-400 at-header4'"
              [truncateLength]="60" />

            @if (item.product.delivery_option) {
              <div class="at-d-flex at-gap-2 at-mt-2 at-mb-4">
                <p
                  class="at-header4 at-py-1 at-px-2 at-bg-secondary2 at-header6 at-rounded-1 at-text-primary1">
                  {{ item.product.delivery_option.name }}
                </p>

                <p
                  class="at-header4 at-py-1 at-px-2 at-bg-secondary2 at-header6 at-rounded-1 at-text-primary1">
                  {{
                    'cart_management_details_screen_estimate_time_arrival_label'
                      | transloco
                  }}: {{ item.product.delivery_option.delivery_time }}
                </p>

                <p
                  class="at-header4 at-py-1 at-px-2 at-bg-secondary2 at-header6 at-rounded-1 at-text-primary1">
                  {{ item.product.delivery_option.fees?.formatted }}
                </p>
              </div>
            }

            <!-- call to actions -->
            <div class="at-d-flex at-align-items-center">
              @if (!isRemoveItemLoading() || selectedItemId !== item.id) {
                <button
                  type="button"
                  class="at-me-4 at-header4 at-text-primary1 remove-a"
                  (click)="deleteItem(item.id)">
                  {{
                    'cart_management_details_screen_remove_button' | transloco
                  }}
                </button>
              } @else {
                <div class="at-text-center at-me-4">
                  <ecommerce-spinner />
                </div>
              }

              @if (
                (!isUploadItemLoading() || selectedItemId !== item.id) &&
                (item.product.is_active || isDeploy) &&
                (item.product.is_in_stock || isDeploy)
              ) {
                <ecommerce-quantity-controller
                  [product_minimum_quantity]="item.product.minimum_quantity"
                  [quantity]="item.quantity"
                  (decreaseProductQuantity)="
                    decreaseQuantity(item.id, item.quantity)
                  "
                  (increaseProductQuantity)="
                    increaseQuantity(item.id, item.quantity)
                  " />
              } @else if (item.product.is_in_stock === false) {
                <div class="at-ps-6 at-text-failure">
                  {{
                    'cart_management_details_screen_out_of_stock_message'
                      | transloco
                  }}
                </div>
              } @else if (item.product.is_active === false) {
                <div class="at-ps-6 at-text-secondary4">
                  {{
                    'cart_management_details_screen_unavailable_message'
                      | transloco
                  }}
                </div>
              } @else {
                <div class="at-ps-6">
                  <ecommerce-spinner />
                </div>
              }
            </div>
          </section>

          <section>
            <p class="at-header5 at-fw-700">{{ item.price.formatted }}</p>
          </section>
        </div>
      }
    </section>
  }
}
