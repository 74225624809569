import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import Pusher from 'pusher-js';
import { User } from '@sentry/angular';
import { Notification } from '../components/notification/notification.interface';
import { NotificationService } from '../components/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher?: Pusher;
  user?: User;

  notificationService = inject(NotificationService);

  initPusher() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
    });
  }

  handlePusherNotification() {
    this.initPusher();
    const token = localStorage.getItem('token');
    setTimeout(() => {
      if (localStorage.getItem('user')) {
        this.user = JSON.parse(localStorage.getItem('user') as string) as User;
        this.pusher = new Pusher(environment.pusher.key, {
          cluster: environment.pusher.cluster,
          authEndpoint: environment.baseUrl + '/api/v1/broadcasting/auth',
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'X-Authorization': environment.xAuthKey,
              'Accept-Language': environment.defaultLang,
              'Access-Control-Allow-Origin': '*',
            },
          },
        });
        const channelName = 'private-buyer-' + this.user.id;
        const channel = this.pusher.subscribe(channelName);
        channel.bind('welcome', (data: Notification) => {
          if (data.id) {
            this.notificationService.addNewNotificationItem(data);
          }
        });
      }
    }, 5000);
  }
}
