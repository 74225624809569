import { Component, OnInit, effect, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ProfileIconComponent } from '../profile-icon/profile-icon.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ButtonComponent } from '@ui-components';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { DropDown } from '../dropdown/dropdown.interface';
import { Avatar } from '../../../pages/auth/settings/avatar.interface';
import { SettingsService } from '../../../pages/auth/settings/settings.service';
import { ProfileIconService } from '../profile-icon/profile-icon.service';
import { TopNavbarEndComponent } from './top-navbar-end/top-navbar-end.component';

@Component({
  selector: 'ecommerce-top-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    DropdownComponent,
    ProfileIconComponent,
    ButtonComponent,
    TranslocoModule,
    TopNavbarEndComponent,
  ],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.scss',
})
export class TopNavbarComponent implements OnInit {
  showLogo = input<boolean>();
  showDropdownSwitcher = input<boolean>();
  showNavSection = input<boolean>();
  showEndSection = input<boolean>();
  isSupplier!: boolean;
  dropdownList: DropDown[] = [
    {
      id: 1,
      name: this.translocoService.translate(
        'landing_screen_environment_mode_switcher_marketplace_label'
      ),
      route: '/product-store',
      active: false,
    },
    {
      id: 2,
      name: this.translocoService.translate(
        'landing_screen_environment_mode_switcher_trade_label'
      ),
      route: '/trade',
      active: false,
    },
  ];
  selectedDropdownItem: DropDown = this.dropdownList[0];
  hideCartIcon!: boolean;
  avatar!: Avatar;
  fullName!: string;
  profileImageUrl!: string;
  navList = [
    {
      label: 'Home',
      href: '#hero-section',
      active: false,
    },
    {
      label: 'Buyers',
      href: '#buyers-section',
      active: false,
    },
    {
      label: 'Suppliers',
      href: '#suppliers-section',
      active: false,
    },
    {
      label: 'Testimonials',
      href: '#testimonials-section',
      active: false,
    },
    {
      label: 'FAQ',
      href: '#faq-section',
      active: false,
    },
  ];

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private settingsService: SettingsService,
    private profileIconService: ProfileIconService
  ) {
    this.getSupplierInfo();
    this.setSelectedDropdownItem();
    this.getAvatarContent();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateSelectedDropdownList(event);
      }
    });
  }

  ngOnInit(): void {
    if (this.isSupplier && window.location.pathname.includes('trade')) {
      this.selectedDropdownItem = this.dropdownList[1];
      this.selectedDropdownItem.id = 2;
    }
    this.scrollSpy();
  }

  getAvatarContent() {
    effect(() => {
      const avatar: Avatar = this.settingsService.avatarContent();
      this.profileImageUrl = this.profileIconService.profileImageUrl();
      this.setAvatarData(avatar);
    });
  }

  setAvatarData(avatar: Avatar) {
    this.avatar = avatar;
    this.profileImageUrl = avatar.image?.url
      ? avatar.image.url
      : this.profileImageUrl;
    this.fullName =
      avatar.name && avatar.name !== '' ? avatar.name : this.fullName;
  }

  updateSelectedDropdownList(event: NavigationEnd) {
    const eventUrl: string = event.url;
    const url: string = eventUrl.split('?')[0];
    this.dropdownList = this.dropdownList.map((item: DropDown) => {
      if (item.active) {
        item.active = false;
      }
      return item;
    });
    if (url.includes('trade')) {
      this.selectedDropdownItem = this.dropdownList[1];
    } else {
      this.selectedDropdownItem = this.dropdownList[0];

      if (url.includes('order-steps')) {
        this.hideCartIcon = true;
      } else {
        this.hideCartIcon = false;
      }
    }
    this.selectedDropdownItem.active = true;
  }

  setSelectedDropdownItem() {
    if (this.dropdownList.length) {
      const selectedDropdownItem = this.dropdownList.find(item => item.active);
      if (selectedDropdownItem) {
        this.selectedDropdownItem = selectedDropdownItem;
      }
    }
  }

  getSupplierInfo() {
    const user = JSON.parse(localStorage.getItem('user') as string);
    const supplier = JSON.parse(localStorage.getItem('supplier') as string);
    if (
      (localStorage.getItem('user') && user.is_supplier) ||
      (localStorage.getItem('supplier') && supplier.id)
    ) {
      this.isSupplier = true;
    }
  }

  scrollToSection(elementId: string) {
    const targetElement = document.querySelector(elementId);
    targetElement?.scrollIntoView();
  }

  scrollSpy() {
    if (this.navList.length) {
      // Add active class to the current dot when the corresponding section is in view
      window.addEventListener('scroll', () => this.updateScrollSpyList());
    }
  }

  updateScrollSpyList() {
    this.navList.forEach(navItem => {
      navItem.active = this.toggleActiveScrollSpyItem(navItem);
    });
  }

  toggleActiveScrollSpyItem(navItem: {
    label: string;
    href: string;
    active: boolean;
  }): boolean {
    const section: HTMLElement = <HTMLElement>(
      document.querySelector(navItem.href)
    );
    const position = section?.getBoundingClientRect();
    if (position && position.top <= 350 && position.bottom >= 350) {
      return true;
    } else {
      return false;
    }
  }
}
