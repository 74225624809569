<div class="sidebar" [ngClass]="{ active: isOpen }">
  <div class="at-px-8 at-pt-6 at-pb-4 at-d-flex at-justify-between">
    <div class="at-d-flex at-justify-between at-align-items-center">
      <span class="at-header3 at-me-3 at-fw-500">{{
        title | truncate: 22
      }}</span>
      @if (is_out_of_stock) {
        <div class="at-fw-700 at-header5 at-text-failure">
          <!-- {{
            'supplier_product_details_screen_out_of_stock_label' | transloco
          }} -->
          out of stock
        </div>
      }
    </div>

    @if (closeIcon) {
      <button (click)="closeSidebar()">
        <img src="/assets/images/svg/close.svg" alt="close Icon" />
      </button>
    } @else {
      <ecommerce-button
        id="supplier_product_details_screen_close_button"
        (click)="closeSidebar()"
        [label]="'supplier_product_details_screen_close_button' | transloco"
        btnClass="at-rounded-pill at-border at-border-secondary4 at-text-primary2 at-px-4 at-py-3 btn-min-width" />
    }
  </div>

  @if (customizeContent) {
    <ng-container [ngTemplateOutlet]="customizeContent" />
  }
</div>

<div
  class="overlay"
  [ngClass]="{ active: isOpen }"
  (click)="closeSidebar()"></div>
