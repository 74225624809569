@if (product()) {
  <p class="at-mb-2 {{ customClasses() }}">
    {{
      (product().name ? product().name : '') +
        (product().coffee_origin ? ' | ' + product().coffee_origin.name : '') +
        (product().coffee_variety
          ? ' | ' + product().coffee_variety.name
          : '') +
        (product().roast_level ? ' | ' + product().roast_level.name : '') +
        (product().package_type &&
        product().weight &&
        product().weight_unit.symbol
          ? ' | ' +
            product().weight +
            ' ' +
            product().weight_unit.symbol +
            ' ' +
            product().package_type
          : '') +
        (product().minimum_quantity
          ? ' (' +
            product().minimum_quantity +
            ' x ' +
            (product().weight ? product().weight : '') +
            ' ' +
            (product().weight_unit && product().weight_unit.symbol
              ? product().weight_unit.symbol
              : '') +
            ' ' +
            (product().package_type ? product().package_type : '') +
            ')'
          : '') | truncate: truncateLength()
    }}
  </p>
}
