import {
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { NotificationGroup, Notification } from '../notification.interface';
import { CommonModule, DatePipe } from '@angular/common';
import { DateAgoPipe } from '../../../pipes/date-ago.pipe';
import { ButtonComponent } from '@ui-components';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'ecommerce-notification-item',
  standalone: true,
  imports: [CommonModule, DateAgoPipe, ButtonComponent, RouterModule],
  providers: [DatePipe],
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss',
})
export class NotificationItemComponent implements OnInit {
  notificationItem = input<NotificationGroup>({
    date: '',
    notifications: [] as Notification[],
  } as NotificationGroup);
  toggleUnread = output<string>();
  todayDate = new Date();
  today = '';
  notificationDate = signal<string>('');

  private datePipe = inject(DatePipe);
  private router = inject(Router);

  ngOnInit(): void {
    this.today = this.datePipe.transform(
      this.todayDate,
      'dd-MM-yyyy'
    ) as string;

    this.notificationDate.set(this.setNotificationDateName());
  }

  setNotificationDateName(): string {
    const dateArray = this.notificationItem().date.split('-');
    const todayString = this.today.split('-');
    if (this.notificationItem().date === this.today) {
      return 'Today';
    } else if (+dateArray[0] + 1 === +todayString[0]) {
      return 'Yesterday';
    } else {
      return this.notificationItem().date;
    }
  }

  setNotificationNavigation(notificationItem: Notification) {
    switch (notificationItem.navigation.type) {
      case 'buyer_registration': {
        this.router.navigate(['/product-store']);
        break;
      }
      case 'buyer_product': {
        this.router.navigate(['/product', notificationItem.navigation.id]);
        break;
      }
      default: {
        this.router.navigate(['/product-store']);
      }
    }
    this.setNotificationToRead(notificationItem.id);
  }

  setNotificationToRead(id: string) {
    this.toggleUnread.emit(id);
  }
}
