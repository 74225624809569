import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BOXO_APIS } from '../constants/endpoints.constant';

@Injectable({
  providedIn: 'root',
})
export class LogoutService extends BaseService {
  logout() {
    const url = BOXO_APIS.POST_LOGOUT;
    return this.post(url, {});
  }
}
