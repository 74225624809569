import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { User } from '../../interfaces/order.interface';
import {
  NotificationGroup,
  NotificationResponse,
} from './notification.interface';
import { NotificationItemComponent } from './notification-item/notification-item.component';

@Component({
  selector: 'ecommerce-notification',
  standalone: true,
  imports: [CommonModule, NotificationItemComponent],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
})
export class NotificationComponent implements OnInit {
  isOpen = false;
  user?: User;
  allNotificationList: NotificationGroup[] = [];
  unreadNotificationList: NotificationGroup[] = [];
  allCount = 0;
  unreadCount = 0;
  viewMode: 'all' | 'unread' = 'all';
  isTradeView = false;

  private notificationService = inject(NotificationService);

  constructor() {
    this.markNotificationAsRead();
  }

  ngOnInit(): void {
    this.isTradeView = window.location.pathname.includes('trade');
    setTimeout(
      () => {
        this.getAllNotification();
        this.getUnreadNotification();
        localStorage.removeItem('delayNotification');
      },
      localStorage.getItem('delayNotification') ? 60000 : 0
    );
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(<string>localStorage.getItem('user'));
    }
  }

  getAllNotification() {
    this.notificationService
      .getNotifications(false)
      .subscribe((response: NotificationResponse) => {
        this.allNotificationList = response.groups;
        this.notificationService.allNotificationList.set(
          this.allNotificationList
        );
        this.allCount = response.notifications_count;
      });
  }

  getUnreadNotification() {
    this.notificationService
      .getNotifications(true)
      .subscribe((response: NotificationResponse) => {
        this.unreadNotificationList = response.groups;
        this.notificationService.unreadNotificationList.set(
          this.unreadNotificationList
        );
        this.notificationService.unreadNotificationCount.set(
          response.notifications_count
        );
        this.unreadCount = response.notifications_count;
      });
  }

  toggleUnreadNotification(id: string) {
    this.closeNotification();
    this.notificationService.toggleUnreadNotifications(id).subscribe(() => {
      this.getAllNotification();
      this.getUnreadNotification();
    });
  }

  closeNotification() {
    this.notificationService.isNotificationOpen.set(false);
    this.isOpen = false;
  }

  markNotificationAsRead() {
    effect(() => {
      const notification = this.notificationService.isNotificationOpen();
      this.isOpen = notification;
    });
  }
}
