<div
  [style.width]="width()"
  [style.height]="height()"
  [ngClass]="{
    'at-rounded-circle at-bg-primary2 at-text-white at-d-flex at-justify-center at-align-items-center profile-icon-size at-paragraph-mega-small':
      placeholder(),
  }">
  @if (imageUrl() && imageUrl() !== '') {
    <img
      [src]="imageUrl()"
      [alt]="'profile pic'"
      [style.width]="width()"
      [style.height]="height()"
      (error)="onProfileImageError($event)"
      class="at-rounded-circle" />
  } @else {
    <p [style.font-size]="fontSize()">
      {{ placeholder() }}
    </p>
  }
</div>
