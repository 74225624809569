import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  HostListener,
  input,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { ButtonComponent, ClickOutSideDirective } from '@ui-components';
import { ProfileIconComponent } from '../../profile-icon/profile-icon.component';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { DropDown } from '../../dropdown/dropdown.interface';
import { OffCanvas } from '../../off-canvas/off-canvas.interface';
import { LogoutService } from '../../../services/logout.service';
import { CartSyncService } from '../cart-sync.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CartService } from '../../../services/cart.service';
import { Avatar } from '../../../../pages/auth/settings/avatar.interface';
import { SettingsService } from '../../../../pages/auth/settings/settings.service';
import {
  SupplierItem,
  Financials,
  CartResponse,
} from '../../../../pages/marketplace/cart/cart.interface';
import { Modal } from '../../modal/modal.interface';
import { ModalService } from '../../modal/modal.service';
import { ProfileIconService } from '../../profile-icon/profile-icon.service';
import { OffCanvasComponent } from '../../off-canvas/off-canvas.component';
import { CartComponent } from '../../../../pages/marketplace/cart/cart.component';
import { ProfileDropdownMenuComponent } from '../profile-dropdown-menu/profile-dropdown-menu.component';
import { User } from '../../../interfaces/order.interface';
import { NotificationComponent } from '../../notification/notification.component';
import { NotificationService } from '../../notification/notification.service';
import { NotificationGroup } from '../../notification/notification.interface';
import { DeployService } from '../../../services/deploy.service';
import { SpinnerComponent } from '../../spinner/spinner.component';
import * as amplitude from '@amplitude/analytics-browser';
import { PusherService } from '../../../services/pusher.service';

@Component({
  selector: 'ecommerce-top-navbar-end',
  standalone: true,
  imports: [
    CommonModule,
    ProfileIconComponent,
    ButtonComponent,
    TranslocoModule,
    RouterModule,
    OffCanvasComponent,
    CartComponent,
    ProfileDropdownMenuComponent,
    NotificationComponent,
    ClickOutSideDirective,
    SpinnerComponent,
  ],
  templateUrl: './top-navbar-end.component.html',
  styleUrl: './top-navbar-end.component.scss',
})
export class TopNavbarEndComponent {
  cartTemplate = viewChild<TemplateRef<unknown>>('cartTemplate');
  logoutModalTemplate = viewChild<TemplateRef<unknown>>('logoutModalTemplate');
  selectedDropdownItem = input<DropDown>(<DropDown>{});
  hideCartIcon = input<boolean>(false);
  isProfileDropdownVisible = false;
  loggedIn!: boolean;
  isSupplier!: boolean;
  profileImagePlaceholder!: string;
  itemsCount = 0;
  cartReference!: string;
  supplierProductList: SupplierItem[] = [];
  financials!: Financials;
  avatar!: Avatar;
  fullName!: string;
  profileImageUrl!: string;
  user?: User;
  allNotificationList: NotificationGroup[] = [];
  unreadNotificationList: NotificationGroup[] = [];
  isDeploy = false;
  logoutLoading = false;
  isResponsiveMenuClosed = true;
  unreadNotificationCount = 0;

  constructor(
    private logoutService: LogoutService,
    private translocoService: TranslocoService,
    private cartSyncService: CartSyncService,
    private cartService: CartService,
    private modalService: ModalService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private profileIconService: ProfileIconService,
    private notificationService: NotificationService,
    private deployService: DeployService,
    private router: Router,
    private pusherService: PusherService
  ) {
    this.loggedIn = !!localStorage.getItem('token');
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user') as string);
    }
    this.getSupplierInfo();
    this.updateProfileImagePlaceholder();
    this.getCartLatestUpdate();
    this.updateCart();
    this.getAvatarContent();
    this.isDeploy = !!this.deployService.transform('test');
    this.getAllNotification();
    // this.pusherService.handlePusherNotification();
  }

  getAvatarContent() {
    effect(() => {
      const avatar: Avatar = this.settingsService.avatarContent();
      this.profileImageUrl = this.user?.profile_image
        ? this.user.profile_image.url
        : this.profileIconService.profileImageUrl();
      this.setAvatarData(avatar);
    });
  }

  setAvatarData(avatar: Avatar) {
    this.avatar = avatar;
    this.profileImageUrl =
      avatar.image && avatar.image.url
        ? avatar.image.url
        : this.profileImageUrl;
    this.fullName =
      avatar.name && avatar.name !== '' ? avatar.name : this.fullName;
  }

  toggleProfileImage() {
    this.isProfileDropdownVisible = !this.isProfileDropdownVisible;
  }

  cartToggle(isOpen: boolean) {
    const offCanvas: OffCanvas = {
      isOpen: isOpen,
      customizeContent: this.cartTemplate(),
      title:
        this.translocoService.translate(
          'cart_management_details_screen_items_title'
        ) +
        ' (' +
        this.itemsCount +
        ')',
      closeIcon: true,
    };
    this.cartSyncService.isCartOpen.set(offCanvas);

    if (isOpen) {
      amplitude.track('track_order_placement_screen_items_drawer_page_loaded');
    }
  }

  notificationToggle(isOpen: boolean) {
    this.notificationService.isNotificationOpen.set(isOpen);
  }

  getAllNotification() {
    effect(() => {
      this.allNotificationList = this.notificationService.allNotificationList();
      this.unreadNotificationList =
        this.notificationService.unreadNotificationList();
      this.unreadNotificationCount =
        this.notificationService.unreadNotificationCount();
    });
  }

  logout() {
    this.logoutLoading = true;
    this.logoutService.logout().subscribe(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('supplier');
      localStorage.removeItem('bank_account');
      localStorage.removeItem('address');
      window.location.href = window.location.origin;
      this.logoutLoading = false;
      this.loggedIn = false;
    });
  }

  updateProfileImagePlaceholder() {
    const user = JSON.parse(localStorage.getItem('user') as string);
    if (user && user.first_name && user.last_name) {
      this.profileImagePlaceholder = user.first_name[0] + user.last_name[0];
      this.fullName = user.first_name + ' ' + user.last_name;
    }
    if (user && user.profile_image) {
      this.profileImageUrl = user.profile_image.url;
    }
  }

  getSupplierInfo() {
    const user = JSON.parse(localStorage.getItem('user') as string);
    const supplier = JSON.parse(localStorage.getItem('supplier') as string);
    if (
      (localStorage.getItem('user') && user.is_supplier) ||
      (localStorage.getItem('supplier') && supplier.id)
    ) {
      this.isSupplier = true;
    }
  }

  getCartLatestUpdate() {
    this.getCartReference();
    this.setCart();
    this.getCartItems();
  }

  getCartReference() {
    if (localStorage.getItem('cart_reference')) {
      this.cartReference = JSON.parse(
        JSON.stringify(localStorage.getItem('cart_reference'))
      );
    } else {
      this.cartReference = '';
    }
  }

  setCart() {
    this.cartSyncService.updateCart.set({
      isShoppingBagLoading: true,
      cart: {
        items_count: 0,
        reference: this.cartReference,
        supplier_items: [],
        financials: {} as Financials,
      },
      can_checkout: false,
    });
  }

  getCartItems() {
    this.cartService
      .getCartItems(this.cartReference)
      .subscribe((response: CartResponse) => {
        response.isShoppingBagLoading = false;
        this.cartSyncService.updateCart.set(response);
        if (!response.code) {
          this.itemsCount = response.cart.items_count;
          this.financials = response.cart.financials;
          this.supplierProductList = response.cart.supplier_items;
          this.cartReference = response.cart.reference;
          localStorage.setItem('cart_reference', this.cartReference);

          if (this.activatedRoute.snapshot.queryParams['openCart']) {
            this.cartToggle(true);
          }
        }
      });
  }

  updateCart() {
    effect(() => {
      const response: CartResponse = this.cartSyncService.updateCart();
      this.itemsCount = response.cart.items_count;
      localStorage.setItem('itemsCount', this.itemsCount.toString());
      this.financials = response.cart.financials;
      this.supplierProductList = response.cart.supplier_items;
      this.cartReference = response.cart.reference;
      localStorage.setItem('cart_reference', this.cartReference);
    });
  }

  closeModal() {
    const modal: Modal = {
      isOpen: false,
    };
    this.modalService.modalContent.set(modal);
  }

  openModal() {
    const modal: Modal = {
      isOpen: true,

      title: this.translocoService.translate(
        'landing_screen_logout_modal_title'
      ),
      description: this.translocoService.translate(
        'landing_screen_logout_modal_description'
      ),
      customizeContent: this.logoutModalTemplate(),
    };
    this.isProfileDropdownVisible = false;
    this.modalService.modalContent.set(modal);
  }

  @HostListener('window:popstate')
  onPopState() {
    this.cartToggle(false);
  }

  handleMenuNavigation(route: string, elementId?: string) {
    this.isResponsiveMenuClosed = true;
    this.router.navigate([route]);

    if (elementId) {
      setTimeout(() => {
        this.scrollToSection(elementId);
      }, 0);
    }
  }

  scrollToSection(elementId: string) {
    const targetElement = document.querySelector(elementId);
    targetElement?.scrollIntoView();
  }
}
