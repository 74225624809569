<div
  class="dropdown-container"
  ecommerceClickOutSide
  (clickOutside)="clickedOutside()">
  <button
    type="button"
    (click)="toggleDropdownList()"
    class="at-d-flex at-align-items-center at-justify-between at-px-4">
    <div class="at-paragraph-small at-text-primary1 at-me-2 at-fw-500">
      {{ selectedDropdownItem()?.name }}
    </div>

    <div class="pt-2px">
      <svg
        width="16"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.3835 5.96667L9.03688 10.3133C8.52355 10.8267 7.68355 10.8267 7.17021 10.3133L2.82355 5.96667"
          stroke="#101b19"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </button>

  @if (showDropdownList) {
    <div class="dropdown-list at-bg-white at-rounded-2 at-shadow-medium">
      @for (item of dropdownList(); track item) {
        <a
          class="dropdown-item at-d-block"
          [routerLink]="item.route"
          [ngClass]="{ active: item.active }">
          {{ item.name }}
        </a>
      }
    </div>
  }
</div>
