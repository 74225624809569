import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDown } from './dropdown.interface';
import { RouterModule } from '@angular/router';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';

@Component({
  selector: 'ecommerce-dropdown',
  standalone: true,
  imports: [CommonModule, RouterModule, ClickOutSideDirective],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  dropdownList = input<DropDown[]>([]);
  selectedDropdownItem = input<DropDown | undefined>();
  showDropdownList!: boolean;

  toggleDropdownList() {
    this.showDropdownList = !this.showDropdownList;
  }

  clickedOutside() {
    this.showDropdownList = false;
  }
}
