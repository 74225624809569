import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { SupplierProduct } from '../../../pages/marketplace/cart/cart.interface';
import { ProductDetails } from '../../../pages/marketplace/product-buyer-details/product-buyer-details.interface';
import { Product } from '../../interfaces/product.interface';

@Component({
  selector: 'ecommerce-product-title-combination',
  standalone: true,
  imports: [CommonModule, TruncatePipe],
  templateUrl: './product-title-combination.component.html',
  styleUrl: './product-title-combination.component.scss',
})
export class ProductTitleCombinationComponent {
  product = input.required<ProductDetails | SupplierProduct | Product>();
  customClasses = input<string>('');
  truncateLength = input<number>(0);
}
